import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from "moment";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "new-collection-update",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      newCollectionId: null,
      valid: false,
      shops: [],
      cities: [],
      categories: [],
      categoriesTree: [],
      selectedCategories: [],
      search: "",
      shopsSearchInput: null,
      citiesSearchInput: null,
      categoriesSearchInput: null,
      newCollection: {
        start: null,
        end: null,
        cities: [],
        shops: [],
        categories: [],
        allCities: false
      },
      citiesIsLoading: false,
      shopsIsLoading: false,
      categoriesIsLoading: false,
      startDateMenu: false,
      endDateMenu: false,
      options: [{
        id: 'fruits',
        label: 'Fruits',
        children: [{
          id: 'apple',
          label: 'Apple 🍎',
          isNew: true,
        }, {
          id: 'grapes',
          label: 'Grapes 🍇',
        }, {
          id: 'pear',
          label: 'Pear 🍐',
        }, {
          id: 'strawberry',
          label: 'Strawberry 🍓',
        }, {
          id: 'watermelon',
          label: 'Watermelon 🍉',
        }],
      }, {
        id: 'vegetables',
        label: 'Vegetables',
        children: [{
          id: 'corn',
          label: 'Corn 🌽',
        }, {
          id: 'carrot',
          label: 'Carrot 🥕',
        }, {
          id: 'eggplant',
          label: 'Eggplant 🍆',
        }, {
          id: 'tomato',
          label: 'Tomato 🍅',
        }],
      }]
    };
  },
  created() {
    this.newCollectionId = this.$route.params.newCollectionId;
    let title =
      this.newCollectionId != null
        ? "New collection details"
        : "Insert new collection";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.newCollectionId != null) {
      ApiService.get(`api/new-collection`, `${this.newCollectionId}`)
        .then(response => {
          this.$log.debug("NewCollection: ", response.data);
          this.newCollection = response.data;
          if (this.newCollection.categories != null && this.newCollection.categories.length > 0 && typeof this.newCollection.categories[0] != 'number') {
            this.selectedCategories = this.newCollection.categories.map((obj) => obj.id);
          }
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCities();
    this.searchShops();
    this.searchCategories();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    removeCityFromList(item) {
      const index = this.newCollection.cities.map(function (e) { return e.id; }).indexOf(item.id);
      if (index >= 0)
        this.newCollection.cities.splice(index, 1);
    },
    removeShopFromList(item) {
      const index = this.newCollection.shops.map(function (e) { return e.id; }).indexOf(item.id);
      if (index >= 0)
        this.newCollection.shops.splice(index, 1);
    },
    listToTree(list) {
      let map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null && node.parentId !== "0") {
          // if you have dangling branches check that map[node.parentId] exists
          if (typeof list[map[node.parentId]].children !== 'undefined' && list[map[node.parentId]].children.length >= 0) {
            list[map[node.parentId]].children.push(node);
          } else {
            list[map[node.parentId]].children = []; // initialize the children
            list[map[node.parentId]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      this.categoriesTree = roots;
    },
    searchCategories() {
      // Items have already been loaded
      if (this.categories.length > 0) return;

      // Items have already been requested
      if (this.categoriesIsLoading) return;

      this.categoriesIsLoading = true;
      ApiService.query("api/shop-categories")
        .then(res => {
          this.categories = res.data;
          this.listToTree(this.categories);
          console.log(this.categoriesTree);
          console.log(this.options);

        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.categoriesIsLoading = false));
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveNewCollection(this.newCollection);
      }
    },
    saveNewCollection(newCollection) {
      this.loading = true;

      this.$log.debug(
        "New collection id to be created: " + newCollection.id
      );

      let shops = [];
      if (newCollection.shops != null && newCollection.shops.length > 0 && typeof newCollection.shops[0] === 'number') {
        newCollection.shops.forEach(function (entry) {
          let shop = {};
          shop['id'] = entry;
          shops.push(shop);
        });
        newCollection.shops = shops;
      }

      let cities = [];
      if (newCollection.cities != null && newCollection.cities.length > 0 && typeof newCollection.cities[0] === 'number') {
        newCollection.cities.forEach(function (entry) {
          let city = {};
          city['id'] = entry;
          cities.push(city);
        });
        newCollection.cities = cities;
      }

      let categories = [];
      this.selectedCategories.forEach(function (entry) {
        let category = {};
        category['id'] = entry;
        categories.push(category);
      });
      newCollection.categories = categories;


      if (newCollection.id != null) {
        ApiService.put(`api/new-collection`, newCollection)
          .then(response => {
            this.$log.debug("New collection changed: " + response);
            this.$emit("newCollectionSaved", "newCollectionSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/new-collection`, newCollection)
          .then(response => {
            this.$log.debug("New collection created: " + response);
            this.$emit("newCollectionSaved", "newCollectionSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    dateStartFormatted: {
      get() {
        if (this.newCollection.start == null || this.newCollection.start === "")
          return null;
        return moment
          .utc(this.newCollection.start)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.newCollection.start = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (this.newCollection.end == null || this.newCollection.start === "")
          return null;
        return moment
          .utc(this.newCollection.end)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.newCollection.end = value;
      }
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
